<script setup lang="ts">
definePageMeta({
  validate: () => {
    return !isProd()
  },
})

const route = useRoute()
const dark = ref(false)
const current = ref(Number(route.query.current ?? 1))
</script>

<template>
  <div class="view-content text-14px" pb="80px md:160px">
    <div class="mt-40px">
      <button class="ld-button mis-4px ld-button-purple" @click="dark = !dark">
        切换底色
      </button>

      <div class="mt-20px rd-8px p-8px" :class="dark && 'bg-[var(--bg-color)] c-#fff'">
        <div>
          <p class="mb-12px">
            purple
          </p>
          <div>
            <span class="inline-block w-72px text-center c-purple-1">purple-1</span>
            <span class="inline-block w-72px text-center c-purple-15">purple-15</span>
            <span class="inline-block w-72px text-center c-purple-2">purple-2</span>
            <span class="inline-block w-72px text-center c-purple-25">purple-25</span>
            <span class="inline-block w-72px text-center c-purple-3">purple-3</span>
            <span class="inline-block w-72px text-center c-purple-35">purple-35</span>
            <span class="inline-block w-72px text-center c-purple-4">purple-4</span>
            <span class="inline-block w-72px text-center c-purple-45">purple-45</span>
            <span class="inline-block w-72px text-center c-purple-5">purple-5</span>
            <span class="inline-block w-72px text-center c-purple-55">purple-55</span>
            <span class="inline-block w-72px text-center c-purple-6">purple-6</span>
            <span class="inline-block w-72px text-center c-purple-65">purple-65</span>
            <span class="inline-block w-72px text-center c-purple-7">purple-7</span>
            <span class="inline-block w-72px text-center c-purple-75">purple-75</span>
            <span class="inline-block w-72px text-center c-purple-8">purple-8</span>
            <span class="inline-block w-72px text-center c-purple-85">purple-85</span>
            <span class="inline-block w-72px text-center c-purple-9">purple-9</span>
          </div>
        </div>

        <div>
          <p class="mb-12px mt-20px">
            yellow
          </p>
          <div>
            <span class="inline-block w-72px text-center c-yellow-1">yellow-1</span>
            <span class="inline-block w-72px text-center c-yellow-15">yellow-15</span>
            <span class="inline-block w-72px text-center c-yellow-2">yellow-2</span>
            <span class="inline-block w-72px text-center c-yellow-25">yellow-25</span>
            <span class="inline-block w-72px text-center c-yellow-3">yellow-3</span>
            <span class="inline-block w-72px text-center c-yellow-35">yellow-35</span>
            <span class="inline-block w-72px text-center c-yellow-4">yellow-4</span>
            <span class="inline-block w-72px text-center c-yellow-45">yellow-45</span>
            <span class="inline-block w-72px text-center c-yellow-5">yellow-5</span>
            <span class="inline-block w-72px text-center c-yellow-55">yellow-55</span>
            <span class="inline-block w-72px text-center c-yellow-6">yellow-6</span>
            <span class="inline-block w-72px text-center c-yellow-65">yellow-65</span>
            <span class="inline-block w-72px text-center c-yellow-7">yellow-7</span>
            <span class="inline-block w-72px text-center c-yellow-75">yellow-75</span>
            <span class="inline-block w-72px text-center c-yellow-8">yellow-8</span>
            <span class="inline-block w-72px text-center c-yellow-85">yellow-85</span>
            <span class="inline-block w-72px text-center c-yellow-9">yellow-9</span>
          </div>
        </div>

        <div>
          <p class="mb-12px mt-20px">
            button
          </p>
          <div class="flex gap-8px">
            <button class="ld-button ld-button-purple">
              purple
            </button>
            <button class="ld-button ld-button-purple" disabled>
              purple disabled
            </button>
            <button class="ld-button ld-button-purple-outline">
              purple outline
            </button>
            <button class="ld-button ld-button-purple-outline" disabled>
              purple outline disabled
            </button>
            <button class="ld-button ld-button-yellow">
              yellow
            </button>
            <button class="ld-button ld-button-yellow" disabled>
              yellow disabled
            </button>
          </div>
        </div>

        <div>
          <p class="mb-12px mt-20px">
            dropdown
          </p>
          <LdDropdown class="relative z-2 w-fit text-16px" :list="['aaaa', 'bbbbb', 'ccccc']">
            <span class="font-700">dropdown</span>
            <template #item="{ item }">
              <LdOptionsItem>
                {{ item }}
              </LdOptionsItem>
            </template>
          </LdDropdown>
        </div>

        <div>
          <p class="mb-12px mt-20px">
            pagination
          </p>
          <LdPagination v-model="current" class="justify-start" :pages="10" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
