<script setup lang="ts">
interface Props {
  /** 页数 */
  pages: number

  scrollToTop?: boolean
}

interface Emits {
  (e: 'update:current', value: number): void
  (e: 'currentChange', value: number): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const current = defineModel<number>('modelValue', { default: 0 })

const filterPage = computed(() => {
  const length = props.pages < 5 ? props.pages : 5
  if (current.value < 5)
    return Array.from({ length }, (_, i) => i + 1)

  else if (current.value > props.pages - 4)
    return Array.from({ length }, (_, i) => props.pages - 5 + i + 1)

  else
    return Array.from({ length }, (_, i) => i + current.value - 2)
})

const route = useRoute()
const localePath = useLocalePath()
function handleUrl(num: number) {
  if (num < 1)
    num = 1
  else if (num > props.pages)
    num = props.pages

  return localePath({
    path: route.path,
    query: {
      ...route.query,
      current: String(num),
    },
  })
}

const { windowScrollTo } = useLayout()
function switchPage(num: number) {
  if (num === current.value)
    return

  if (num < 1)
    num = 1
  else if (num > props.pages)
    num = props.pages

  current.value = num
  emit('currentChange', num)

  if (props.scrollToTop) {
    windowScrollTo()
  }
}
</script>

<template>
  <div class="pagination flex-center" text="14px" gap="12px">
    <NuxtLink :class="{ 'c-op-30! pointer-events-none': current <= 1 }" :to="handleUrl(1)" aria-label="first page" @click="switchPage(1)">
      <svg class="flip-rtl" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" transform="matrix(-1 0 0 1 24 0)" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3536 8.10636C15.1583 7.9111 14.8417 7.9111 14.6464 8.10636L11.1109 11.6419C10.9157 11.8372 10.9157 12.1537 11.1109 12.349L14.6464 15.8845C14.8417 16.0798 15.1583 16.0798 15.3536 15.8845C15.5488 15.6893 15.5488 15.3727 15.3536 15.1774L12.1716 11.9954L15.3536 8.81347C15.5488 8.61821 15.5488 8.30162 15.3536 8.10636Z" fill="currentcolor" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25732 8.5C8.98118 8.5 8.75732 8.72386 8.75732 9V15C8.75732 15.2761 8.98118 15.5 9.25732 15.5C9.53347 15.5 9.75732 15.2761 9.75732 15V9C9.75732 8.72386 9.53347 8.5 9.25732 8.5Z" fill="currentcolor" />
      </svg>
    </NuxtLink>
    <NuxtLink :class="{ 'c-op-30! pointer-events-none': current <= 1 }" :to="handleUrl(current - 1)" aria-label="previous page" @click="switchPage(current - 1)">
      <svg class="flip-rtl" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" transform="matrix(-1 0 0 1 24 0)" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3536 8.10636C13.1583 7.9111 12.8417 7.9111 12.6464 8.10636L9.11091 11.6419C8.91565 11.8372 8.91565 12.1537 9.11091 12.349L12.6464 15.8845C12.8417 16.0798 13.1583 16.0798 13.3536 15.8845C13.5488 15.6893 13.5488 15.3727 13.3536 15.1774L10.1716 11.9954L13.3536 8.81347C13.5488 8.61821 13.5488 8.30162 13.3536 8.10636Z" fill="currentcolor" />
      </svg>
    </NuxtLink>

    <!-- 非首尾的时候展示 -->
    <template v-if="current > 4">
      <NuxtLink :to="handleUrl(1)" @click="switchPage(1)">
        <span>1</span>
      </NuxtLink>
      <NuxtLink :to="handleUrl(current - 3)" @click="switchPage(current - 3)">
        <span>...</span>
      </NuxtLink>
    </template>
    <!-- 只展示五条 -->
    <template v-for="i in filterPage" :key="i">
      <NuxtLink :class="{ 'c-purple-5! after:op-100! font-700': current === i }" :to="handleUrl(i)" @click="switchPage(i)">
        <span>{{ i }}</span>
      </NuxtLink>
    </template>

    <!-- 非首尾的时候展示 -->
    <template v-if="current < pages - 3">
      <NuxtLink :to="handleUrl(current + 3)" @click="switchPage(current + 3)">
        <span>...</span>
      </NuxtLink>
      <NuxtLink :to="handleUrl(pages)" @click="switchPage(pages)">
        <span>{{ pages }}</span>
      </NuxtLink>
    </template>

    <NuxtLink :class="{ 'c-op-30! pointer-events-none': current >= pages }" :to="handleUrl(current + 1)" aria-label="next page" @click="switchPage(current + 1)">
      <svg class="flip-rtl" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6464 8.10636C10.8417 7.9111 11.1583 7.9111 11.3536 8.10636L14.8891 11.6419C15.0843 11.8372 15.0843 12.1537 14.8891 12.349L11.3536 15.8845C11.1583 16.0798 10.8417 16.0798 10.6464 15.8845C10.4512 15.6893 10.4512 15.3727 10.6464 15.1774L13.8284 11.9954L10.6464 8.81347C10.4512 8.61821 10.4512 8.30162 10.6464 8.10636Z" fill="currentcolor" />
      </svg>
    </NuxtLink>
    <NuxtLink :class="{ 'c-op-30! pointer-events-none': current >= pages }" :to="handleUrl(pages)" aria-label="last page" @click="switchPage(pages)">
      <svg class="flip-rtl" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="4" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.64645 8.10636C8.84171 7.9111 9.15829 7.9111 9.35355 8.10636L12.8891 11.6419C13.0843 11.8372 13.0843 12.1537 12.8891 12.349L9.35355 15.8845C9.15829 16.0798 8.84171 16.0798 8.64645 15.8845C8.45118 15.6893 8.45118 15.3727 8.64645 15.1774L11.8284 11.9954L8.64645 8.81347C8.45118 8.61821 8.45118 8.30162 8.64645 8.10636Z" fill="currentcolor" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7427 8.5C15.0188 8.5 15.2427 8.72386 15.2427 9V15C15.2427 15.2761 15.0188 15.5 14.7427 15.5C14.4665 15.5 14.2427 15.2761 14.2427 15V9C14.2427 8.72386 14.4665 8.5 14.7427 8.5Z" fill="currentcolor" />
      </svg>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.pagination a {
  --at-apply:  relative min-w-24px h-24px flex-center c-#161636 c-op-70 transition-all-300 hover:c-purple-5 active:c-purple-6;

  span {
    --at-apply: px-6px;
  }

  &::after {
    --at-apply: absolute content-empty left-0 right-0 bottom-0 h-2px op-0 rd-5px bg-#6964fd transition-all-300;
  }
}
</style>
